.react-pdf__Page__canvas {
    margin: auto;
}

.annotationLayer {
    height: 0;
}

.react-pdf__Document {
    max-height: 800px;
    max-width: 1100px;
    overflow-y: scroll;
    background-color: rgb(82, 86, 89);
    
}
.react-pdf__Document:focus{
    outline: 4px solid #d4d4d4;
}

.react-pdf__message {
    color: white;
}

.pdf-first-child {
    padding-top: 40px;
}

.react-pdf__Page__textContent {
    display: none
}