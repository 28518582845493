.mobile-breadcrumb-title {
	font-size: 18px;
	margin: -20px 0 7px 0;
	display: flex
}

.loading-canvas-front {
	flex-grow: 1;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width:320px) and (max-width: 767px) {
	.loading-canvas-front {
		height: 520px;
	}
}

.left-panel p {
	color: #FFFFFF;
}

.left-panel a {
	color: #FFFFFF;
	font-weight: bold;
}
