.btnPill {
  color: #ffffff;
  background-color: rgba(0, 82, 156, 1);
  border-radius: 25px;
  padding: 11px;
  position: initial;
  min-width: 300px;
  height: 44px;
  text-align: center;
  cursor: pointer;
  margin: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border: none;
  text-transform: inherit;
  font-size: 18px;
}

.btnPill:focus {
  outline: 0;
}

.btnPill:active {
  background-color: rgba(0, 82, 156, 1);
}

.btnPill:hover {
  background-color: rgba(0, 82, 156, 1);
}

.btnRound {
  border-radius: 2px;
}

.btnLinkPrimary {
  color: rgba(0, 82, 156, 1);
  margin: 10px;
  text-decoration: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px;
  font-weight: 600;
}

.btnLinkPrimary:hover {
  color: rgba(0, 82, 156, 1);;
  outline: 0;
  text-decoration: none;
}

.btnLinkPrimary:focus {
  outline: 0;
}

/* .active {
  background-color: #007382;
} */

.disabled {
  background-color: #ebebeb;
  color: #9e9c9c;
  pointer-events: none;
}

.disabled:active {
  background-color: #fafafa;
}

.disabled:hover {
  background-color: #fafafa;
}
