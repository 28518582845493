.display_review_flex_center {
  display: flex;
  min-height: 0px;
  justify-content: space-between;
}

.display_review_flex_center.columns {
  flex-direction: column;
}

.display_review_flex_center > div {
  display: flex;
  font-size: 16px;
  color: #454545;
  text-align: right;
  line-height: 21px;
}

.display_review_flex_center > div.group-question {
  font-size: 26px;
  font-weight: bold;
  width: 75% !important;
}

.display_bold {
  font-weight: bold;
}

.display_normal {
  font-weight: normal;
}

.display_review_question {
  width: 50% !important;
  min-width: 50% !important;
}

.display_review_question.columns {
  width: 100% !important;
  min-width: 100% !important;
}

.display_label {
  flex: 0 0 100%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .display_review_question {
    padding: 10px;
  }

}

.display_update_button {
 background-color: white;
 color: blue;
 border: none;
 margin-right: 10%;
}

.display_review_answer {
  font-weight: bold;
  /* margin-left: 15%; */
}

.display_review_answer.columns {
  padding-top: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .display_review_answer.columns {
    margin: 0% 10% 0% 4%;
  }
}

.review-dotted-line-break {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  /* border-top: 2px dashed #b3b3b3; */
  height: 1px;
  color: transparent;
  margin-right: 10%;
}
.faq-line-break {
  margin-top: 15px;
  height: 1px;
  border-top: 1px solid #b3b3b3 ;
}

.review-solid-line {
  margin-top: 10px !important;
  margin-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
  height: 1px;
  color: transparent;
}

.review-solid-line-2 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  border-top: 1px solid #0e1941;
  height: 1px;
  color: transparent;
}

@media screen and (max-width: 767px) {
  .display_update_button {
    text-align: right;
    margin-top: 10px;
    margin-right: 0%;
  }

  .display_review_question {
    width: 100% !important;
    min-width: 50% !important;
  }

}
